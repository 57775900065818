.search-page {
	display: flex;

	&__content {
		display: grid;
		row-gap: 1em;
	}

	&__spinner {
		margin: 0 0.5em;
	}
}
