.curation-page {
	display: flex;
	justify-content: center;
	padding: 2em 0;

	&__content {
		padding: 2em 5em;

		border: 1px solid lightgray;
		border-radius: 8px;

		display: grid;
		row-gap: 1em;
	}
}
