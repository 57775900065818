.result-card {
	width: 350px;

	&__description {
		cursor: default;
	}

	.ellipser {
		&__link {
			color: #1397cd;

			&:hover {
				cursor: pointer;
			}
		}
	}

	&__actions {
		display: grid;
		row-gap: 5px;
		width: fit-content;
	}

	&__error {
		color: red;

		&.show {
			visibility: visible;
		}

		&.hide {
			visibility: hidden;
		}
	}
}
