.result-list {
	margin: 1em 0;

	&__header {
		margin: 0.5em 0 ;
	}

	&__hashtag {
		color: skyblue;
	}

	&__sections {
		display: grid;
		row-gap: 3em;
	}
}

.result-section {
	&__items {
		display: grid;
		grid-template-columns: repeat(3, min-content);

		column-gap: 2em;
		row-gap: 2em;

		margin-bottom: 1.5em;
	}
}
